import Vue from "vue";
import Vuex from "vuex";
import dict from './modules/dict.js'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
	  userInfo:'' 
  },
  getters: {},
  mutations: {
	  updateUserInfo(state, payload) {
	      state.userInfo = payload;
	  },
  },
  actions: {},
  modules: {
	  dict
  },
  plugins: [createPersistedState()],
});
