<template>
	<div class="page-container">
		<Header></Header>
		<div class="page-content">
			<router-view />
		</div>
	</div>
</template>

<script>
import Header from '@/layout/components/Header'	

export default {
	name: 'BasePage',
	components:{
		Header
	},
	data () {
	  return {
		
	  }
	},
	computed:{
	   
	},
	created () {},
	methods: {}
  }
</script>

<style lang="scss" scoped>
.page-container{
	height: 100vh;
	display: flex;
	flex-direction: column;
	.page-content{
		flex: 1;
		height: 0;
		overflow: hidden;
	}
}	
</style>